import store from '@/store';
import * as authApi from '@/plugins/firebase.auth';
import {auth, firestore} from '@/plugins/firebase.init';
import {Business, BusinessCategory, BusinessContact, RootState, Update} from '@/domain/model/types';
import {
  addDoc,
  deleteDoc,
  deleteField,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch
} from 'firebase/firestore';
import {buildVersion, dynamicLinkConfig} from '@/plugins/firebase.config';
import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {profileStore} from '@/store/modules/profile';
import {
  appVersionDoc,
  business,
  businessCategories,
  businesses,
  businessCustomer,
  fcmTokens,
  releaseNotes,
  user,
  verification,  
  smsBusinesses
} from '@/data/firebase';
import {getIdTokenResult, sendEmailVerification, signInWithEmailAndPassword} from 'firebase/auth';

const defaultState: RootState = {
  version: '0.0.0',
  currentUser: {
    uid: null,
    displayName: null,
    photoURL: null
  },
  t2bUser: null,
  businessCategories: [],
  sourceBusiness: {
    id: null,
    name: null,
    category: null,
    street: null,
    city: null,
    state: null,
    country: null,
    zipCode: null,
    address: null,
    phone: null,
    email: null,
    website: null,
    description: null,
    openHours: null,
    logoUrl: '@/assets/image_placeholder.png',
    geopoint: null,
    businessCategory: {
      index: 0,
      name: '',
      tag: '',
      tags: []
    },
    tags: [],
    createdDate: null,
    updatedDate: null,
    verified: false
  },
  business: {
    id: null,
    name: null,
    category: null,
    street: null,
    city: null,
    state: null,
    country: null,
    zipCode: null,
    address: null,
    phone: null,
    email: null,
    website: null,
    description: null,
    openHours: null,
    logoUrl: '@/assets/image_placeholder.png',
    geopoint: null,
    taxId: '',
    businessCategory: {
      index: 0,
      name: '',
      tag: '',
      tags: []
    },
    tags: [],
    createdDate: null,
    updatedDate: null,
    verified: false
  },
  directory: [],
  originals: [],
  imagePlaceholder: '@/assets/image_placeholder.png',
  businessImageFile: null,
  isActive: false,
  collections: {
    BUSINESSES: 'businesses',
    DIRECTORY: 'directory',
    DIRECTORY_EXP: 'directory-exp',
    TEXT_SESSIONS: 'textSessions',
    MESSAGES: 'messages'
  },
  currentPage: 0,
  latestUpdates: []
};

@Module({name: 'application-module', store, dynamic: true})
export default class ApplicationModule extends VuexModule {

  private _business: Business | null = defaultState.business;
  private _businessCategories: BusinessCategory[] | null = defaultState.businessCategories;
  private _businessImageFile: File | null = defaultState.businessImageFile;
  private _collections: any = defaultState.collections;
  private _currentPage: number = defaultState.currentPage;
  private _imagePlaceholder: string = defaultState.imagePlaceholder;
  private _originals: BusinessContact[] = defaultState.originals;
  private _sourceBusiness: Business | null = defaultState.sourceBusiness;
  private _version: string = defaultState.version;
  private _error: string = '';
  private _signingEmailError: string = '';
  private _signingPwdError: string = '';
  private _signing: boolean = false;
  private _needUpdate: boolean = false;
  private _latestUpdates = defaultState.latestUpdates
  private _smsSenderId:string = '';

  get error() {
    return this._error;
  }

  get version() {
    return this._version;
  }

  get businessCategories() {
    return this._businessCategories;
  }

  get business() {
    return this._business;
  }

  get businessId() {
    return this._business?.id;
  }

  get businessName() {
    return this._business?.name;
  }

  get originals() {
    return this._originals;
  }

  get businessImageFile() {
    return this._businessImageFile;
  }

  get imagePlaceholder() {
    return this._imagePlaceholder;
  }

  get isAdmin() {
    const user = profileStore.t2bUser;
    const roles = user && user.roles;
    return user ? (roles ? roles.admin : user.admin) : false;
  }

  get isOwner() {
    const user = profileStore.t2bUser;
    const roles = user && user.roles;
    return user ? (roles ? roles.superAdmin : (user.superAdmin || user.owner)) : false;
  }

  get collections() {
    return this._collections;
  }

  get isBusinessExist() {
    const user = profileStore.t2bUser
    return user && user.business;
  }

  get currentPage() {
    return this._currentPage;
  }

  get signing() {
    return this._signing;
  }

  get signingEmailError() {
    return this._signingEmailError;
  }

  get signingPwdError() {
    return this._signingPwdError;
  }

  get needUpdate() {
    return this._needUpdate
  }

  get latestUpdates() {
    return this._latestUpdates
  }

  get smsSenderId() {
    return this._smsSenderId;
  }

  @Mutation
  public setError(error) {
    this._error = error;
    this._signing = false;
  }

  @Mutation
  public setCurrentPage(page) {
    this._currentPage = page;
  }

  @Mutation
  public setBusinessImageFile(value) {
    this._businessImageFile = value;
  }

  @Mutation
  public setBusinessCategories(businessCategories) {
    this._businessCategories = businessCategories;
  }

  @Mutation
  public setBusiness(business) {
    this._business = {...this._business, ...business};
    this._sourceBusiness = {...this._sourceBusiness, ...business};
  }

  @Mutation
  public setName(name) {
    if (this._business !== null) {
      this._business.name = name;
    }
  }

  @Mutation
  public setAddress(address) {
    if (this._business !== null) {
      this._business.address = address;
    }
  }

  @Mutation
  public setPhone(phone) {
    if (this._business !== null) {
      this._business.phone = phone;
    }
  }

  @Mutation
  public setEmail(email) {
    if (this._business !== null) {
      this._business.email = email;
    }
  }

  @Mutation
  public setWebsite(website) {
    if (this._business !== null) {
      this._business.website = website;
    }
  }

  @Mutation
  public setDescription(description) {
    if (this._business !== null) {
      this._business.description = description;
    }
  }

  @Mutation
  public setLogoUrl(logoUrl) {
    if (this._business !== null) {
      this._business.logoUrl = logoUrl;
    }
  }

  @Mutation
  public setBusinessCategory(category) {
    if (this._business !== null) {
      this._business.businessCategory = category;
    }
  }

  @Mutation
  public setCategory(category) {
    if (this._business !== null) {
      this._business.category = category;
    }
  }

  @Mutation
  public setBusinessDirectoryOrigin(originals) {
    this._originals = originals;
  }

  @Mutation
  public clearBusiness() {
    this._business = null;
    this._sourceBusiness = null;
  }

  @Mutation
  public clearCompanyLogo() {
    if (this._business !== null) {
      delete this._business.companyLogoUrl
    }
  }

  @Mutation
  public addBusinessCategory(category) {
    if (!this._businessCategories) {
      this._businessCategories = [];
    }
    this._businessCategories.push(category);
  }

  @Mutation
  public addOriginalContact(contact) {
    if (!this._originals) {
      this._originals = [];
    }
    this._originals.push(contact);
  }

  @Mutation
  public addOriginalContactAtIndex({index, contact}) {
    if (!this._originals) {
      this._originals = [];
    }
    this._originals[index] = contact;
  }

  @Mutation
  public removeOriginalContactAtIndex(index) {
    if (!this._originals) {
      return;
    }
    this._originals.splice(index, 1);
  }

  @Mutation
  public startSignIn() {
    this._signingEmailError = '';
    this._signingPwdError = '';
    this._signing = true;
  }

  @Mutation
  public finishSignIn() {
    this._signing = false;
  }

  @Mutation
  public signInEmailError(error: string) {
    this._signingEmailError = error;
    this._signing = false;
  }

  @Mutation
  public signInPwdError(error: string) {
    this._signingPwdError = error;
    this._signing = false;
  }

  @Mutation
  public resetApplicationState() {
    this._business = defaultState.business;
    this._businessCategories = defaultState.businessCategories;
    this._businessImageFile = defaultState.businessImageFile;
    this._collections = defaultState.collections;
    this._currentPage = defaultState.currentPage;
    this._imagePlaceholder = defaultState.imagePlaceholder;
    this._originals = defaultState.originals;
    this._sourceBusiness = defaultState.sourceBusiness;
    this._version = defaultState.version;
  }

  @Action
  public hideUser({uid, hidden}) {
    return updateDoc(user(uid), 'hidden', hidden)
  }

  @Action
  public hideUsers({users, hidden}) {
    const batch = writeBatch(firestore);
    for (const item of users) {
      batch.update(user(item.id), 'hidden', hidden)
    }
    return batch.commit();
  }

  @Action
  public deleteUser(uid) {
    return deleteDoc(user(uid));
  }

  @Action
  public deleteUsers(users) {
    const batch = writeBatch(firestore)
    for (const item of users) {
      batch.delete(user(item.id))
    }
    return batch.commit();
  }

  @Action
  public async deleteCustomer(uid) {    
    if(this.businessId && uid){      
      await deleteDoc(user(uid));
      await deleteDoc(businessCustomer(this.businessId, uid));    
      return true;
    }
    else{
      return false;
    }
  }

  @Action
  public disableUser({uid, disable}) {
    const moreFields = disable ? [
      'workingStatus.type', 2,
      'workingStatus.name', 'Absent',
      'workingStatus.duration', deleteField(),
      'workingStatus.autocancel', false
    ] : [];
    return updateDoc(user(uid), 'disabled', disable, ...moreFields);
  }

  @Action
  public disableUsers({users, disable}) {
    const batch = writeBatch(firestore);
    const moreFields = disable ? [
      'workingStatus.type', 2,
      'workingStatus.name', 'Absent',
      'workingStatus.duration', deleteField(),
      'workingStatus.autocancel', false
    ] : [];
    for (const item of users) {
      batch.update(user(item.id), 'disabled', disable, ...moreFields)
    }
    return batch.commit();
  }

  @Action
  public async signIn({email, password}) {
    this.startSignIn();
    try {
      const credentials = await signInWithEmailAndPassword(auth, email, password);
      const result = await getIdTokenResult(credentials.user, false);
      if (result.claims.associate) {
        await profileStore.refreshUser();
      } else {
        this.signInEmailError('Is not valid business associate account');
        await this.signOut();
        this.finishSignIn();
        return false;
      }
    } catch (error: any) {
      switch (error.code) {
        case 'auth/invalid-email':
          this.signInEmailError('Email address is not valid');
          break;
        case 'auth/user-disabled':
          this.signInEmailError('User is disabled');
          break;
        case 'auth/user-not-found':
          this.signInEmailError('User not found');
          break;
        case 'auth/wrong-password':
          this.signInPwdError('Wrong password');
          break;
        case 'auth/too-many-requests':
        case 'auth/user-token-expired':
          this.setError(error.message);
          break
        default: {
          this.setError('Failed to sign in');
        }
      }
      this.finishSignIn();
      return false;
    }
    this.finishSignIn();
    return true;
  }

  @Action
  public async signOut() {
    const uid = profileStore.currentUser?.uid;
    if (!uid) {
      return
    }
    try {
      await profileStore.detachStatus()
    } catch (e) {
    }

    // fix - status not changed, after user logged in mobile app
    try {      
      await profileStore.detachOnlineStatus()
    } catch (e) { 
    }

    const fcmTokensQuery = query(fcmTokens,
      where('uid', '==', uid),
      where('platform', '==', 'web'));
    const snapshots = await getDocs(fcmTokensQuery)
    for (const doc of snapshots.docs) {
      await deleteDoc(doc.ref)
    }
    await authApi.signOutCurrent();
  }

  // deprecated
  //todo: REVISE!!!
  @Action
  public async createUser({fullName, email, password, verificationId}) {
    const credentials = await authApi.createUser(fullName, email, password);
    const createUser = credentials.user;
    if (createUser) {
      profileStore.setCurrentUser(createUser);
      const verificationDoc = await getDoc(verification(verificationId));
      // @ts-ignore
      const company = verificationDoc.data().company || '';
      const businessRef = await addDoc(businesses, company)
      await setDoc(user(createUser.uid), {business: {id: businessRef.id, name: company}}, {merge: true});
      if (!createUser.emailVerified) {
        await sendEmailVerification(createUser, dynamicLinkConfig.actionCodeSettings);
      }
    }
  }

  @Action
  public sendSignInLink(email) {
    return authApi.sendSignInLink(email);
  }

  @Action
  public async completeSignUp({fullName, email, password}) {
    const currentUser = await authApi.completeSignUp(fullName, email, password);
    profileStore.setCurrentUser(currentUser);
  }

  @Action
  public async signInWithGooglePopUp() {
    const currentUser = await authApi.signInWithGooglePopUp();
    profileStore.setCurrentUser(currentUser);
  }

  @Action
  public confirmPasswordReset({code, password}) {
    return authApi.confirmResetPassword(code, password);
  }

  @Action
  public updateBusinessCategory(category) {
    this.setBusinessCategory(category);
    this.setCategory(`/businessCategories/${category.id}`);
  }

  @Action
  public fetchBusinessCategories() {
    if (!!this._businessCategories!.length) {
      return null;
    }
    return onSnapshot(businessCategories,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          this.addBusinessCategory({id: change.doc.id, ...change.doc.data()});
        });
      },
      (error) => console.log(error));
  }

  @Action
  public fetchBusiness() {
    if (this._business && this._business.id) {
      return null;
    }
    const businessId = this._business && this._business.id;
    if (!businessId) {
      return null;
    }
    return onSnapshot(business(businessId),
      (snapshot) => {
        const data = snapshot.data();
        if (data) {
          data.id = snapshot.id;
          data.logoUrl = data.logoUrl ? (data.logoUrl !== '' ? data.logoUrl : this.imagePlaceholder) : this.imagePlaceholder;
          this.setBusiness(data);
        }
      },
      (error) => console.log(error)
    );
  }

  @Action
  public async loadBusiness() {
    const t2bUser = profileStore.t2bUser;
    const businessId = t2bUser?.business?.id;
    if (!businessId) {
      console.log('application_store -> no business id');
      return null;
    }
    try {
      const snapshot = await getDoc(business(businessId));
      const data = snapshot.data();
      if (data) {
        data.id = snapshot.id;
        data.logoUrl = data.logoUrl ? (data.logoUrl !== '' ? data.logoUrl : this.imagePlaceholder) : this.imagePlaceholder;
        this.setBusiness(data);
        window.dataLayer?.push({
          event: 'User Data',
          business_id: snapshot.id
        })
        return data;
      }
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  @Action
  public async loadBusinessCategories() {
    if (!!this._businessCategories!.length) {
      return;
    }
    const snapshot = await getDocs(businessCategories)
    this.setBusinessCategories(snapshot.docs
      .map((doc) => {
        return {id: doc.id, ...doc.data()} as BusinessCategory;
      })
      .sort((c1, c2) => c1.index > c2.index && 1 || -1)
    );
  }

  @Action
  public selectPage(page) {
    this.setCurrentPage(page);
  }

  @Action
  public clearState() {
    this.resetApplicationState();
  }

  @Action
  public listenForUpdates() {
    return onSnapshot(appVersionDoc, (snapshot) => {
      if (snapshot.exists()) {
        const versionName = snapshot.data()?.versionName;
        this.checkNeedUpdate(versionName)
      }
    });
  }

  @Mutation
  private checkNeedUpdate(versionName?: string) {
    this._needUpdate = !!versionName && buildVersion !== versionName
  }

  @Action
  public async loadReleaseNotes() {
    const querySnapshot = await getDocs(query(releaseNotes, orderBy('buildDate', 'desc')))
    this.setLatestUpdates(querySnapshot.docs.map((doc) => doc.data() as Update))
  }

  @Mutation
  private setLatestUpdates(data: Update[]) {
    this._latestUpdates = data
  }

  @Action
  public async loadSmsBusiness() {
    try{
        this.setSmsSenderId('')

        const qSnap = await getDocs(query(smsBusinesses, where("businessId", "==", this.businessId)));    
        if (qSnap.size) {
          const data = qSnap.docs[0].data();
          if(data?.status && data?.phoneNumber && data.phoneNumber.toString().length >= 10){            
            this.setSmsSenderId(data.phoneNumber)
          }      
        } else {
          console.log("invalid smsSenderId")
        }
    } catch (e) {
      console.log("Error in loadSmsBusiness")
      console.log(e);
    }
  }

  @Mutation
  private setSmsSenderId(smsSenderId?: string) {
    this._smsSenderId = smsSenderId || '';
  }
}

export const applicationStore = getModule(ApplicationModule);
